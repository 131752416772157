import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Box, Heading, Image, Spinner, Text } from "grommet"
import { Instagram as InstagramIcon } from "grommet-icons"
import PhotoAlbum from "react-photo-album"
import usePhotos from "../utils/usePhotos"

const renderPhoto = ({
  layoutOptions,
  imageProps: { alt, style, src },
  photo: { igUrl },
}) => (
  <Box
    onClick={() => window.open(igUrl, "_blank")}
    elevation="small"
    style={{
      border: "2px solid #eee",
      borderRadius: "10px",
      boxSizing: "content-box",
      width: style?.width,
      padding: `${layoutOptions.padding - 2}px`,
      cursor: "pointer",
    }}
  >
    <Image
      fit="contain"
      src={src}
      alt={alt}
      style={{ ...style, width: "100%", padding: 0 }}
    />
  </Box>
)

const PhotoPage = () => {
  const photos = usePhotos()
  return (
    <Layout>
      <Seo title="photo" />
      <Box flex="grow">
        <Heading> my photos</Heading>
        <Box
          elevation="small"
          round="small"
          align="center"
          justify="center"
          direction="row"
          gap="small"
          pad="medium"
          width="fit-content"
          alignSelf="center"
          margin="large"
          border
          onClick={() => window.open("https://instagr.am/__p_z_pix", "_blank")}
          style={{
            cursor: "pointer",
          }}
        >
          <InstagramIcon size="large" />
          <Text size="large">@__p_z_pix</Text>
        </Box>
        {photos.length === 0 ? (
          <Box align="center" justify="center" height="small">
            <Spinner size="large" />
          </Box>
        ) : (
          <PhotoAlbum
            layout="rows"
            photos={photos}
            renderPhoto={renderPhoto}
            padding={14}
          />
        )}
      </Box>
    </Layout>
  )
}

export default PhotoPage
